import { inject, Injectable, signal } from '@angular/core';
import { Workshop, WorkshopApiService } from '../../api/workshop-api.service';
import { waitForSignal } from '../../shared/helpers/wait';
import { ResolveFn } from '@angular/router';
import { StorageService } from '../services/storage.service';
import dayjs from 'dayjs';
import { firstValueFrom } from 'rxjs';
import { AnalyticsService } from '../services/analytics.service';

@Injectable({ providedIn: 'root' })
export class WorkshopStore {
  private readonly storage = inject(StorageService);
  private readonly workshopApi = inject(WorkshopApiService);
  private readonly analytics = inject(AnalyticsService);

  readonly workshop = signal<Workshop | null>(null);
  readonly isLoading = signal(false);

  constructor() {
    const cachedWorkshop = this.storage.getItem('workshop');
    if (cachedWorkshop) {
      try {
        const workshop = JSON.parse(cachedWorkshop) as Workshop;
        const token = this.storage.getItem('access_token');
        if (
          dayjs(workshop.start_date).toDate().getTime() > Date.now() + 48 * 60 * 60 * 1000 &&
          !token &&
          !workshop.registered
        ) {
          this.workshop.set(workshop);
          this.isLoading.set(false);
        }
      } catch (e) {
        console.log(e);
        this.storage.removeItem('workshop');
      }
    }
  }

  async loadWorkshop(): Promise<Workshop | null> {
    this.load();
    if (this.workshop()) {
      return this.workshop();
    }
    await waitForSignal(this.isLoading, false);
    return this.workshop();
  }

  async register(in120sec = false) {
    const workshop = this.workshop() ?? (await this.loadWorkshop());
    if (workshop) {
      const success = await firstValueFrom(
        this.workshopApi.registerWorkshop(workshop.id, in120sec)
      );
      if (success) {
        this.analytics.lead();
        this.workshop.update(w => (w ? { ...w, registered: true } : null));
      }
    }
  }

  private load() {
    this.isLoading.set(true);
    this.workshopApi.getWorkshop().subscribe(workshop => {
      this.workshop.set(workshop);
      this.storage.setItem('workshop', JSON.stringify(workshop));
      this.isLoading.set(false);
    });
  }
}

export const WorkshopResolver: ResolveFn<Workshop | null> = () =>
  inject(WorkshopStore).loadWorkshop();
