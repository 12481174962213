import { inject, Injectable } from '@angular/core';
import { ENVIRONMENT } from '../core/tokens/env.token';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { catchError, map, Observable, of, throwError } from 'rxjs';
import { UtmTagsService } from '../core/services/utm-tags-service';
import { Api } from './api';

export const WorkshopStatuses = {
  notStarted: 'not-started',
  startSoon: 'start-soon',
  live: 'live',
  finished: 'finished',
} as const;
export type WorkshopStatus = (typeof WorkshopStatuses)[keyof typeof WorkshopStatuses];

export type Workshop = {
  id: string;
  name: string;
  created_at: string;
  updated_at: string;
  deleted_at: string | null;
  start_date: string;
  description: string;
  complexity: string;
  placeholder: string | null;
  video: string | null;
  record: string | null;
  slug: string;
  offer: string | null;
  status: WorkshopStatus;
  registered: boolean;
};

@Injectable({
  providedIn: 'root',
})
export class WorkshopApiService extends Api {
  private readonly env = inject(ENVIRONMENT);
  private readonly http = inject(HttpClient);
  private readonly utmService = inject(UtmTagsService);

  getWorkshop(): Observable<Workshop | null> {
    return this.http.get<Workshop | null>(this.buildUrl('api/v2/workshop/active')).pipe(
      catchError((err: HttpErrorResponse) => {
        if (err.status === 404) {
          return of(null);
        }
        return throwError(() => err);
      })
    );
  }

  registerWorkshop(id: Workshop['id'], in120sec = false): Observable<boolean> {
    const params = this.setUtmTagsToHttpParams(this.utmService.getUtmTags());
    return this.http
      .post<{
        success: boolean;
      }>(this.buildUrl(`api/v2/workshop/${id}/register`), { in120sec }, { params })
      .pipe(
        map(({ success }) => success),
        catchError(() => of(false))
      );
  }

  buildUrl(path: string): string {
    return `${this.env.apiBaseUrl}/${path}`;
  }
}
